import "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid, Row, Col } from "../../components/FlexBox/FlexBox";

import moment from "moment";
import { API_V1 } from "../../settings/api-endpoints";
import jwt_decode from "jwt-decode";

import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Grid as MatGrid,
  Button,
  Slide,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Input,
  TablePagination,
} from "@material-ui/core";
import { Wallet } from "../UserDetails/UserTransactions";
import Paper from "@material-ui/core/Paper";
import { numberFormat } from "../../utils/currency-format";

import {
  DialogContentText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import SearchBar from "../../components/search-bar";

import exportFromJSON from "export-from-json";
import uuidv4 from "uuid/v4";
import OtpInput from "react-otp-input";
import { SnackBarContext } from "../../context/snackbar/snackbar.context";
import { prepareSnackBarProperites } from "../../components/snackbar";
import OtpModalComponent from "../../components/otp-modal";
import { User } from "../../types/user";
import { BackdropContext } from "../../context/backdrop/backdrop.context";
import { theme } from "../../theme";
import { Reviews, ReviewStatus } from "../../services/reviews";

import ReactSelect from 'react-select';


const entityTypes = [
  {
    label: 'Product',
    value: 'PRODUCT',
  },
  {
    label: 'Category',
    value: 'CATEGORY',
  }
];

const statuses = [
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  }
]

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    minHeight: 400,
    maxHeight: 600,
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
});

function SingleRow(props) {
  const { row, index, approveReview, rejectReview } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="left">{index}</TableCell>
        <TableCell align="left">{row.review}</TableCell>
        <TableCell align="left">{row.rating}</TableCell>
        <TableCell align="left">{row.userName}</TableCell>
        <TableCell align="left">{row.status}</TableCell>
        <TableCell >
          <MatGrid container direction="row" spacing={10}>
            {((row?.status === 'PENDING') || (row?.status === 'REJECTED')) && (
              <MatGrid item md={6}>
                <Button
                  onClick={() => approveReview(row.id)}
                  size="small"
                  variant="outlined"
                >
                  Approve
                </Button>
              </MatGrid>
            )}
            {((row?.status === 'APPROVED') || (row?.status === 'PENDING')) && (
              <MatGrid item md={6}>
                <Button
                  onClick={() => rejectReview(row.id)}
                  size="small"
                  color="primary"
                  variant="outlined"
                >
                  Reject
                </Button>
              </MatGrid>
            )}
          </MatGrid>
        </TableCell>
      </TableRow>
    </React.Fragment >
  );
}

const ProductReviews = () => {
  const token = localStorage.getItem("melmaa_token");
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const { snackBarDispatch } = useContext(SnackBarContext);
  const { backdropDispatch } = useContext(BackdropContext);

  const [reviews, setReviews] = useState(undefined);
  const [forceRerender, setForcereRender] = useState(false);
  const [entityType, setEntitiyType] = useState({
    label: 'Product',
    value: 'PRODUCT',
  });
  const [status, setStatus] = useState({
    label: 'Pending',
    value: 'PENDING',
  });

  const filteredData = [];
  const handleSearch = (e) => { };

  useEffect(() => {
    fetchReviews(1);
  }, [entityType, status]);

  useEffect(() => {
    if (reviews) {
      fetchReviews(reviews.pageNumber);
    }
  }, [forceRerender]);

  const fetchReviews = async (page) => {
    setLoading(true);
    const reviews = await Reviews.fetchAll({ pageNumber: page, entityType: entityType?.value, status: status?.value });
    const { data: { data } } = reviews;

    setReviews(data);
    setLoading(false);
  }

  const handleChangePage = (e, page) => {
    fetchReviews(page + 1);
  }

  const updateStatus = async (e, status) => {
    if (!window.confirm('Are you sure you want to update Status ? ')) return;
    try {
      backdropDispatch({ open: true });
      await Reviews.update({ id: e, status: status });
      setForcereRender(!forceRerender);
      backdropDispatch({ open: false });
    } catch (error) {
      console.log(error);
      backdropDispatch({ open: false });
    }
  }

  return (
    <>
      <MatGrid
        container
        justify="space-between"
        style={{
          zIndex: 10,
          position: 'relative'
        }}
        spacing={2}
      >
        <MatGrid item md={3}>
          <h3>Reviews</h3>
        </MatGrid>
        <MatGrid item md={3}>
          <ReactSelect
            value={entityType}
            options={entityTypes}
            onChange={(e) => setEntitiyType(e)}
            placeholder="Select Entity Type"
            isClearable
          />
        </MatGrid>

        <MatGrid item md={3}>
          <ReactSelect
            options={[]}
            isClearable
            isDisabled={!entityType}
            placeholder={entityType?.label ? `select ${entityType?.label} to filter` : 'Select Entity Type'}
          />
        </MatGrid>
        <MatGrid item md={3}>
          <ReactSelect
            value={status}
            options={statuses}
            onChange={(e) => setStatus(e)}
            placeholder="Select Status"
            isClearable
          />
        </MatGrid>
      </MatGrid>
      <Grid fluid={true}>

        <Row>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              {!loading && (
                <Table stickyHeader size={"small"} aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">S.No</TableCell>
                      <TableCell align="left">Review</TableCell>
                      <TableCell align="left">Rating</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviews?.responses.map((row, index) => {
                      return (
                        <SingleRow approveReview={(e) => updateStatus(e, ReviewStatus.APPROVED)} rejectReview={(e) => updateStatus(e, ReviewStatus.REJECTED)} key={index + 1} row={row} index={index + 1} />
                      );
                    })}
                  </TableBody>
                </Table>
              )}
              {!loading && reviews?.responses.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <span> No records Found </span>
                </div>
              ) : null}
              {loading ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <span> Loading records... </span>
                </div>
              ) : null}
              {loading ? null : (
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={reviews?.totalCount}
                  rowsPerPage={reviews?.pageSize}
                  page={reviews.pageNumber - 1}
                  onChangePage={handleChangePage}
                />
              )}
            </TableContainer>
          </Paper>
        </Row>
      </Grid>
    </>
  );
};

export default ProductReviews;
