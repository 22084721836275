import { get, post, put } from "../config/api";
import { getHeaders } from "./utils";

const ReviewEntityType = {
  PRODUCT: "PRODUCT",
  CATEGORY: "CATEGORY",
  ORDER: "ORDER",
  COMMUNICATION: "COMMUNICATION",
};

const ReviewStatus = {
  PENDING: 'PENDING', APPROVED: 'APPROVED', REJECTED: 'REJECTED',
}

export const Reviews = {
  fetchAll: ({ entityId = undefined, entityType = undefined, pageNumber = 1, pageSize = 10, status = undefined }) => {
    const headers = getHeaders();
    if (headers) {
      return get(`feedback/list`, { headers, params: { entityId, entityType, pageNumber, pageSize, status } });
    }
  },

  update: (values) => {
    if (!values.id) {
      return;
    }
    const headers = getHeaders();
    if (headers) {
      return put("feedback/update", values, { headers });
    }
  },
};

export { ReviewEntityType, ReviewStatus };
