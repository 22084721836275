import React, { useContext, lazy, Suspense } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import {
  LOGIN,
  PRODUCTS,
  WAREHOUSE,
  DASHBOARD,
  ORDERS,
  SETTINGS,
  REPORTS,
  CUSTOMERS,
  USERDETAIL,
  STAFF_MEMBERS,
  SITE_SETTINGS,
  ADMIN_LOGIN,
  RECEPTIONIST,
  DISTRIBUTOR_LOGIN,
  ROLES,
  WAREHOUSE_STOCK,
  ORDER_DETAILS,
  SUPER_ADMIN_LOGIN,
  KYC_ADMIN,
  KYCLIST,
  KYC_DETAIL,
  INVOICE_DOWNLOAD,
  WAYBILL_DOWNLOAD,
  PAYMENTS,
  CATEGORIES,
  PRODUCTS_META,
  REVIEWS,
} from "./settings/constants";
import AuthProvider, { AuthContext } from "./context/auth";
import { InLineLoader } from "./components/InlineLoader/InlineLoader";
import { ProductMeta } from "./containers/Products/ProductMeta";
import ProductReviews from "./containers/Reviews/Reviews";

const Products = lazy(() => import("./containers/Products/Products"));
const AdminLayout = lazy(() => import("./containers/Layout/Layout"));
const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Warehouse = lazy(() => import("./containers/Warehouse/Warehouse"));
const WarehouseStock = lazy(
  () => import("./containers/Warehouse/Warehousestock")
);
const Orders = lazy(() => import("./containers/Orders/Orders"));
const OrderDetails = lazy(() => import("./containers/Orders/OrderDetails"));
const WayBill = lazy(() => import("./containers/Orders/WayBill"));
const Invoice = lazy(() => import("./containers/Orders/Invoice"));

const Settings = lazy(() => import("./containers/Settings/Settings"));
const Reports = lazy(() => import("./containers/Reports/Reports"));
// const Payments = lazy(() => import("./containers/Payments/Payments"));

const SiteSettingForm = lazy(
  () => import("./containers/SiteSettingForm/SiteSettingForm")
);
const StaffMembers = lazy(
  () => import("./containers/StaffMembers/StaffMembers")
);
const Customers = lazy(() => import("./containers/Customers/Customers"));

const Categories = lazy(() => import("./containers/Categories"));
// const Customerdetail = lazy(
//   () => import("./containers/UserDetails/UserDetails")
// );

// const KycDetail = lazy(() => import("./containers/Kyc/KycDetail"));

// const Kyclist = lazy(() => import("./containers/Kyc/Kyclist"));
// const Kycdetails = lazy(() => import('./containers/Kyc/KycDetails'));

const Login = lazy(() => import("./containers/Login/Login"));
const NotFound = lazy(() => import("./containers/NotFound/NotFound"));

// coupons are used for testing;
const Userdetail = lazy(() => import("./containers/UserDetails/UserDetails"));

/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Routes = () => {
  let query = useQuery();

  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS_META}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductMeta productId={query.get("productId")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={REVIEWS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductReviews />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={WAREHOUSE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Warehouse />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAREHOUSE_STOCK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WarehouseStock wareid={query.get("wareid")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Orders />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDER_DETAILS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OrderDetails orderid={query.get("orderid")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICE_DOWNLOAD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Invoice orderid={query.get("orderid")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAYBILL_DOWNLOAD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WayBill waybill_id={query.get("waybill_id")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORIES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Categories />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          {/* <PrivateRoute path={KYCLIST}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Kyclist />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}

          <PrivateRoute path={USERDETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Userdetail userid={query.get("userid")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          {/* <PrivateRoute path={KYC_DETAIL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <KycDetail userid={query.get("userid")} />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}

          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={REPORTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Reports />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          {/* 
          <PrivateRoute path={PAYMENTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Payments />
              </Suspense>
            </AdminLayout>
          </PrivateRoute> */}

          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>

          <Route path={LOGIN}>
            <Login role={ROLES.ADMIN} />
          </Route>
          <Route path={SUPER_ADMIN_LOGIN}>
            <Login role={ROLES.SUPER_ADMIN} />
          </Route>

          <Route path={ADMIN_LOGIN}>
            <Login role={ROLES.ADMIN} />
          </Route>

          <Route path={DISTRIBUTOR_LOGIN}>
            <Login role={ROLES.DISTRIBUTOR} />
          </Route>

          <Route path={KYC_ADMIN}>
            <Login role={ROLES.KYC_ADMIN} />
          </Route>

          <Route path={RECEPTIONIST}>
            <Login role={ROLES.RECEPTIONIST} />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
