import React, { useState } from "react"; 

import { BackdropContext } from "./backdrop.context"; 
import { Backdrop, CircularProgress, BackdropProps } from "@material-ui/core"; 


const INITIAL_STATE: BackdropProps = {
    open: false
};


export const BackdropProvider: React.FunctionComponent = ({ children }) => {
  const [backdropState, backdropDispatch] = useState(INITIAL_STATE);
  return (
    <BackdropContext.Provider value={{ backdropState, backdropDispatch }}>
      <Backdrop style={{
        zIndex: 99999999,
        color: 'white'
      }} className="custom-backdrop" {...backdropState}>
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}>
        <CircularProgress color="inherit" />
        <br/>
        &nbsp;please wait...
        </div>
      </Backdrop>
      
      {children} 
    </BackdropContext.Provider>
  );
};


