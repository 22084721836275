import { get, post, put } from "../config/api";
import { getHeaders } from "./utils";

const SubCategoryType = {
  PRODUCT: "PRODUCT",
  CATEGORY: "CATEGORY",
};

export const Category = {
  fetchAll: () => {
    const headers = getHeaders();
    if (headers) {
      return get("category/all", { headers });
    }
  },

  create: (values) => {
    const headers = getHeaders();
    if (headers) {
      return post("category/add", values, { headers });
    }
  },

  assign: (values) => {
    const headers = getHeaders();
    if (headers) {
      return post("category/assign", values, { headers });
    }
  },

  unassign: (values) => {
    const headers = getHeaders();
    if (headers) {
      return post("category/unassign", values, { headers });
    }
  },

  update: (values) => {
    if (!values.id) {
      return;
    }
    const headers = getHeaders();
    if (headers) {
      return put("category/update", values, { headers });
    }
  },

  updateImage: (values) => {
    if (!values.id) {
      return;
    }
    const headers = getHeaders();
    if (headers) {
      return put("category/update", values, { headers });
    }
  },
};

export { SubCategoryType };
