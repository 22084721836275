import { post, put } from "../config/api";
import { getHeaders } from "./utils";

// Available values : PRODUCT, CATEGORY, ORDER, COMMUNICATION
export const Faq = {
  addFaq: ({ type: entityType, question, answer, entityId }) => {
    const headers = getHeaders();
    if (headers) {
      return post(
        "admin/faq",
        { entityType, question, answer, entityId },
        { headers }
      );
    }
  },

  editFaq: ({ id, question, answer }) => {
    const headers = getHeaders();
    if (headers) {
      return put(`admin/faq`, { question, answer, id }, { headers });
    }
  },

  deleteFaq: (entityId) => {
    const headers = getHeaders();
    if (headers) {
      return put("admin/faq/" + entityId, {}, { headers });
    }
  },
};
