import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, HashRouter } from "react-router-dom";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider } from "baseui";
import { theme } from "./theme";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import "./theme/global.css";
import {
  createMuiTheme,
  ThemeProvider as MaterialProvider,
} from "@material-ui/core/styles";
import { SnackBarProvider } from "./context/snackbar/snackbar.provider";
import { BackdropProvider } from "./context/backdrop/backdrop.provider";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

const queryClient = new QueryClient();

const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#ff1493",
    },
  },
});

const constantMock = window.fetch;
window.fetch = function () {
  // Get the parameter in arguments
  // Intercept the parameter here
  console.log("window.fetchwindow.fetchwindow.fetch");
  return constantMock.apply(this, arguments);
};

function App() {
  const engine = new Styletron();

  return (
    <QueryClientProvider client={queryClient}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <MaterialProvider theme={customTheme}>
            <BackdropProvider>
              <SnackBarProvider>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </SnackBarProvider>
            </BackdropProvider>
          </MaterialProvider>
        </BaseProvider>
      </StyletronProvider>
    </QueryClientProvider>
  );
}

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
