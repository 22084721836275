import React, { useEffect, useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import { Card, CardContent } from "@material-ui/core";

const convertToSortableProps = (list, listKey) => {
  if (!list) return [];

  return list.map((item) => ({
    ...item,
    text: (item && item[listKey]) || "Key error",
  }));
};

const SortableList = ({
  list,
  onChangeOrder,
  type = "list",
  deletable = false,
  editable = false,
  onEdit = undefined,
  onDelete = undefined,
}) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(list);
  }, [list]);

  const onDrop = ({ removedIndex, addedIndex }) => {
    console.log({ removedIndex, addedIndex });
    setItems((items) => arrayMoveImmutable(items, removedIndex, addedIndex));
    onChangeOrder({ list: items, removedIndex, addedIndex });
  };

  return (
    <Card variant="outlined">
      <CardContent>
        {!items || (items.length === 0 && <>No records </>)}
        {items && items.length !== 0 && (
          <List style={{ minWidth: "30vw" }}>
            <Container
              dragHandleSelector=".drag-handle"
              lockAxis="y"
              onDrop={onDrop}
            >
              {items.map(({ id, text, image }) => (
                <Draggable key={id}>
                  <Card style={{ marginBottom: "0.4vw" }} variant="outlined">
                    <ListItem>
                      <ListItemIcon className="drag-handle">
                        <DragHandleIcon />
                      </ListItemIcon>
                      {type === "list" && <ListItemText primary={text} />}
                      {type === "image" && (
                        <img height="100" src={image} alt="rearrangList" />
                      )}
                      <ListItemSecondaryAction>
                        {editable && (
                          <ListItemIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => onEdit && onEdit(id)}
                          >
                            <EditIcon />
                          </ListItemIcon>
                        )}
                        {deletable && (
                          <ListItemIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => onDelete && onDelete(id)}
                          >
                            <DeleteOutline />
                          </ListItemIcon>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Card>
                </Draggable>
              ))}
            </Container>
          </List>
        )}
      </CardContent>
    </Card>
  );
};

export default SortableList;

export { convertToSortableProps };
