const getHeaders = () => {
  const token = localStorage.getItem("melmaa_token");

  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  return null;
};

const getToken = () => {
  const token = localStorage.getItem("melmaa_token");
  return `Bearer ${token}`;
}

export { getHeaders, getToken };
