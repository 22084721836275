import { FormControl, Input, InputLabel, Typography } from "@material-ui/core";
import { FieldProps } from "formik";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { theme } from "../../theme";
export interface InputProps extends FieldProps<any> {
  label: string;
  inputProps: any;
  isUpperCase: boolean;
  disabled: boolean;

  onChange: (e: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldStyle: {
      marginTop: theme.spacing(2),
    },
  })
);

const InputField = (props: InputProps) => {
  const classes = useStyles();

  const { label, field, form, inputProps, isUpperCase, disabled, onChange } = props;
  const error: boolean =
    form.touched[field.name] && form.errors[field.name] ? true : false;

  return (
    <FormControl className={classes.fieldStyle} variant="standard" fullWidth>
      <InputLabel error={error}>{label}</InputLabel>
      <Input
        error={error}
        fullWidth
        {...inputProps}
        {...field}
        disabled={disabled}
        onChange={(e) => {
          onChange && onChange(e);
          isUpperCase
            ? form.setFieldValue(
              field.name,
              e.target.value ? e.target.value.toUpperCase() : ""
            )
            : field.onChange(e);
        }}
        labelWidth={0}
      />

      {error && (
        <Typography style={{ marginBottom: 2 }} variant="caption" color="error">
          {form.errors[field.name]}
        </Typography>
      )}
    </FormControl>
  );
};

export default InputField;
