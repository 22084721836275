import { post } from "../config/api";
import { getHeaders } from "./utils";

export const Upload = {
  uploadFiles: ({ file, type = "GENERIC", id = 0 }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("function", type);
    formData.append("type", `${type}_${id}`);

    const headers = getHeaders();
    if (headers) {
      return post("admin/upload", formData, {
        headers,
      });
    }
  },
};
