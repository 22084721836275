import React, { useContext, useEffect, useState } from "react";
import DrawerBox from "../../components/DrawerBox/DrawerBox";
import { FormFields, FormLabel } from "../../components/FormFields/FormFields";
import Uploader from "../../components/UploadField";
import { API_V1 } from "../../settings/api-endpoints";
import { BackdropContext } from "../../context/backdrop/backdrop.context";
import { SnackBarContext } from "../../context/snackbar/snackbar.context";
import { Upload } from "../../services/upload";
import { Product } from "../../services/product";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { Category, SubCategoryType } from "../../services/categories";
import FaqList, { FaqTypes } from "../../components/FaqList";
import Select from "react-select";
import { prepareSnackBarProperites } from "../../components/snackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const ComboProducts = ({ productId }) => {
  const { snackBarDispatch } = useContext(SnackBarContext);
  const { backdropDispatch } = useContext(BackdropContext);
  const [products, setProducts] = useState([]);
  const [comboProducts, setComboProducts] = useState(undefined);

  useEffect(() => {
    fetchProducts();
    fetchComboProducts();
  }, []);

  const fetchProducts = async () => {
    await Product.getAllProducts()
      .then((response) => {
        const { data } = response;
        const {
          data: { responses: productInfo },
        } = data;
        setProducts(productInfo);
        backdropDispatch({ open: false, transitionDuration: 1000 });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchComboProducts = async () => {
    await Product.getComboProducts(productId)
      .then((response) => {
        const { data } = response;

        const { data: comboProducts } = data;

        setComboProducts(comboProducts);

        console.log(comboProducts);
        backdropDispatch({ open: false, transitionDuration: 1000 });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [comboProduct, setComboProduct] = useState(undefined);

  const getProducts = () => {
    if (!products) return [];
    const produtsOptions = products.filter((x) => {
      if (x.id === +productId) return false;
      return true;
    });

    return produtsOptions.map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  };

  const handleProductChange = (event) => {
    setComboProduct(event);
  };

  const onAddCombo = () => {
    if (!comboProduct) return;
    backdropDispatch({ open: true, transitionDuration: 1000 });
    Product.addCombo({
      productId: +productId,
      comboId: comboProduct.value,
    })
      .then((response) => {
        const snackbarproperties = prepareSnackBarProperites(
          "Combo Product Added",
          snackBarDispatch,
          "success",
          3000
        );
        snackBarDispatch(snackbarproperties);

        setComboProduct(undefined);
        backdropDispatch({ open: false, transitionDuration: 1000 });
        fetchComboProducts();
      })
      .catch((e) => {
        console.log(e);
        const snackbarproperties = prepareSnackBarProperites(
          e.status_message || "Combo Product Not Added",
          snackBarDispatch,
          "error",
          3000
        );
        snackBarDispatch(snackbarproperties);

        backdropDispatch({ open: false, transitionDuration: 1000 });
      });
  };

  const deleteCombo = (comboId) => {
    if (!comboId) return;

    if (!window.confirm("Are you sure you want to delete this combo?")) return;

    backdropDispatch({ open: true, transitionDuration: 1000 });
    Product.deleteCombo(comboId)
      .then((response) => {
        const snackbarproperties = prepareSnackBarProperites(
          "Combo Product Deleted",
          snackBarDispatch,
          "success",
          3000
        );
        snackBarDispatch(snackbarproperties);
      })
      .catch((e) => {
        console.log(e);
        const snackbarproperties = prepareSnackBarProperites(
          e.status_message || "Combo Product Not Deleted",
          snackBarDispatch,
          "error",
          3000
        );
        snackBarDispatch(snackbarproperties);
      })
      .finally(() => {
        backdropDispatch({ open: false, transitionDuration: 1000 });
        fetchComboProducts();
      });
  };

  return (
    <>
      <FormFields>
        <FormLabel>Combo Products</FormLabel>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Select
              value={comboProduct}
              onChange={handleProductChange}
              options={getProducts()}
              isClearable
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Button
              color="primary"
              variant="contained"
              disabled={!comboProduct}
              onClick={() => {
                onAddCombo();
              }}
            >
              Add Combo
            </Button>
          </Grid>
        </Grid>
      </FormFields>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {comboProducts &&
              comboProducts.map((product) => {
                const { productB, id } = product;
                return (
                  <Grid item sm={12} md={12} key={productB.id}>
                    <Grid container>
                      <Grid item sm={2} md={2}>
                        <img
                          src={productB.image}
                          alt={productB.name}
                          style={{
                            height: "60px",
                          }}
                        />
                      </Grid>
                      <Grid item sm={5} md={7}>
                        <Typography variant="subtitle1">
                          {productB.name}
                        </Typography>
                        <Typography variant="caption">
                          {productB.price}
                        </Typography>
                      </Grid>
                      <Grid item sm={5} md={3}>
                        <Button onClick={() => deleteCombo(id)}>
                          Delete
                          <DeleteIcon style={{ color: "red" }} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

const ProductCategories = ({ productId, primaryCategoryId = undefined, setPrimaryCategoryId }) => {
  const [categories, setCategories] = useState([]);
  const { snackBarDispatch } = useContext(SnackBarContext);
  const { backdropDispatch } = useContext(BackdropContext);

  const [productCategories, setProductCategories] = useState([]);
  useEffect(() => {
    fetchCategories();
    fetchProductCategories();
  }, []);

  const fetchProductCategories = async () => {
    await Product.getCategories(productId)
      .then((response) => {
        const { data } = response;
        const { data: productCategories } = data;
        // console.log("data, response", productCategories);
        setProductCategories(productCategories);
        // setCategories(categories);
        backdropDispatch({ open: false, transitionDuration: 1000 });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const fetchCategories = () => {
    Category.fetchAll().then((response) => {
      const { data } = response;
      const {
        data: { responses: categoriesAPIList },
      } = data;
      setCategories(categoriesAPIList);
    });
  };

  const [category, setCategory] = useState(null);

  const getCategories = () => {
    return categories.map((x) => {
      return {
        value: x.id,
        label: x.name,
      };
    });
  };

  const handleCategoryChange = (event) => {
    setCategory(event);
  };

  const onAssignCategory = () => {
    backdropDispatch({ open: true, transitionDuration: 1000 });
    const values = {
      categoryId: category.value,
      entityId: productId,
      entityType: SubCategoryType.PRODUCT,
    };

    Category.assign(values)
      .then(async (response) => {
        backdropDispatch({ open: false, transitionDuration: 1000 });
        setCategory(null);
        const snackbarproperties = prepareSnackBarProperites(
          "Category Assigned",
          snackBarDispatch,
          "success",
          3000
        );
        snackBarDispatch(snackbarproperties);

        fetchCategories();
        fetchProductCategories();
      })
      .catch((e) => {
        const snackbarproperties = prepareSnackBarProperites(
          e.status_message || "Category Not Assigned",
          snackBarDispatch,
          "error",
          3000
        );
        snackBarDispatch(snackbarproperties);
        backdropDispatch({ open: false, transitionDuration: 1000 });
      });
  };

  const onUnAssignCategory = (categoryId) => {
    if (!window.confirm('Are you sure you want to UnAssign this category?')) return;
    backdropDispatch({ open: true, transitionDuration: 1000 });
    const values = {
      categoryId: categoryId,
      entityId: productId,
      entityType: SubCategoryType.PRODUCT,
    };

    Category.unassign(values)
      .then(async (response) => {
        backdropDispatch({ open: false, transitionDuration: 1000 });
        setCategory(null);
        const snackbarproperties = prepareSnackBarProperites(
          "Category UnAssigned",
          snackBarDispatch,
          "success",
          3000
        );
        snackBarDispatch(snackbarproperties);

        fetchCategories();
        fetchProductCategories();
      })
      .catch((e) => {
        const snackbarproperties = prepareSnackBarProperites(
          e.status_message || "Category Not Assigned",
          snackBarDispatch,
          "error",
          3000
        );
        snackBarDispatch(snackbarproperties);
        backdropDispatch({ open: false, transitionDuration: 1000 });
      });
  };

  return (
    <>
      <FormFields>
        <FormLabel>Categories</FormLabel>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Select
              value={category}
              onChange={handleCategoryChange}
              options={getCategories()}
              isClearable
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Button
              color="primary"
              variant="contained"
              disabled={!category}
              onClick={() => {
                onAssignCategory();
              }}
            >
              Assign
            </Button>
          </Grid>
        </Grid>
      </FormFields>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            {/* {<Grid item>No Categories</Grid>} */}
            {productCategories.map((category) => (
              <Grid item md={12}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item md={1}>
                    <img
                      src={category.image}
                      alt={category.name}
                      style={{
                        height: "40px",
                      }}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="subtitle1">{category.name}</Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Tooltip title="Unassign category">
                      <Button onClick={() => onUnAssignCategory(category.id)}>
                        <RemoveCircleIcon style={{ color: "red" }} />
                      </Button>
                    </Tooltip>
                    {category.id !== primaryCategoryId && (
                      <Tooltip title="Make as primary category">
                        <Button>
                          <RadioButtonUncheckedIcon
                            style={{ color: "green" }}
                          />
                        </Button>
                      </Tooltip>
                    )}
                    {category.id === primaryCategoryId && (
                      <Tooltip title="Remove primary category">
                        <Button onClick={() => setPrimaryCategoryId(category.id)}>
                          <CheckCircleIcon style={{ color: "green" }} />
                        </Button>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export const ProductMeta = ({ productId }) => {
  const [files, setFiles] = useState([]);

  const { snackBarDispatch } = useContext(SnackBarContext);
  const { backdropDispatch } = useContext(BackdropContext);

  const [product, setProduct] = useState(null);
  const [primaryCategoryId, setPrimaryCategoryId] = useState(undefined);

  const handleUploader = (files) => {
    setFiles(files);
  };

  useEffect(() => {
    backdropDispatch({ open: true, transitionDuration: 1000 });
    fetchProduct();
  }, []);

  const fetchProduct = async () => {
    await Product.getProduct(productId)
      .then((response) => {
        const { data } = response;
        const { data: productInfo } = data;
        setProduct(productInfo);

        backdropDispatch({ open: false, transitionDuration: 1000 });
        console.log(productInfo);
        setFiles([]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [productCategories, setProductCategories] = useState([]);
  const handleMultiChange = ({ value }) => {
    setProductCategories(value);
  };

  const history = useHistory();

  const updateImages = (productImageUrls) => {
    backdropDispatch({ open: true, transitionDuration: 1000 });
    Product.addProductImage({
      productId,
      productImageUrls: [productImageUrls],
    })
      .then(async (addProductImageResponse) => {
        backdropDispatch({ open: false, transitionDuration: 1000 });
        await fetchProduct();
      })
      .catch(() => {
        backdropDispatch({ open: true, transitionDuration: 1000 });
      });
  };

  const deleteImage = async (productImageId) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete ?")) {
      backdropDispatch({ open: true, transitionDuration: 1000 });
      await Product.deleteImage({ productId, imageId: productImageId })
        .then(async (response) => {
          console.log("delete response", response);
          await fetchProduct();
          backdropDispatch({ open: false, transitionDuration: 1000 });
        })
        .catch(() => {
          backdropDispatch({ open: false, transitionDuration: 1000 });
        });
    }
  };

  const handleSetPrimaryCategoryId = (categoryId) => {

  }
  return (
    <>
      <div>
        <div>
          <IconButton aria-label="back " onClick={() => history.goBack()}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <Grid container spacing={4} justify="center">
          <Typography variant="h5">{product?.name}</Typography>
        </Grid>
        <br />
        <hr />
        <Grid container spacing={4}>
          <Grid item sm={12} md={6}>
            <FormFields>
              <FormLabel>Product Images</FormLabel>
              <DrawerBox
                overrides={{
                  Block: {
                    style: {
                      width: "100%",
                      height: "auto",
                      padding: "30px",
                      borderRadius: "3px",
                      backgroundColor: "#ffffff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
                  },
                }}
              >
                <Uploader
                  onChange={handleUploader}
                  imagesList={product?.productImages}
                  deleteImage={deleteImage}
                  onUploadImages={updateImages}
                  files={files}
                  id={product?.id}
                  type={"PRODUCT"}
                  multlple
                />
              </DrawerBox>
            </FormFields>
            <FaqList
              list={product?.faqs}
              type={FaqTypes.PRODUCT}
              id={product?.id}
              listKey="question"
              refresh={() => fetchProduct()}
              onQuestionAdded={() => fetchProduct()}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <ProductCategories productId={productId} primaryCategoryId={primaryCategoryId} setPrimaryCategoryId={handleSetPrimaryCategoryId} />
            <ComboProducts productId={productId} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
