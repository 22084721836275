// export const API_END_POINT = `https://devapi.femenlife.com/api/`;
export const API_END_POINT = process.env.REACT_APP_API_URL;

// export const API_END_POINT = `http://35.154.112.77:7777/api/`;

export const API_COMMON_V1 = `${API_END_POINT}v1/`;

export const API_V1 = {
  login: `${API_COMMON_V1}login`,
  forgotPwd: `${API_COMMON_V1}forgot/password`,

  // dashboard APIs
  getDashboardData: `${API_COMMON_V1}dashboard/statistics`,
  getUserDashboardData: `${API_COMMON_V1}dashboard/count/users`,
  getOrderDashboardData: `${API_COMMON_V1}dashboard/count/orders`,

  // Prouct crud APIs
  getAllProducts: `${API_COMMON_V1}product/all`,
  addProduct: `${API_COMMON_V1}product/add`,
  updateProduct: `${API_COMMON_V1}product/update`,

  uploadImage: `${API_COMMON_V1}admin/upload`,
  addProductImages: `${API_COMMON_V1}product/add-productImages`,
  rmProductImage: `${API_COMMON_V1}product/remove-product-image`,

  // order list and details APIs and get delhivery APIs
  getOrderList: `${API_COMMON_V1}admin/orders`,
  getWarehouseOrderList: `${API_COMMON_V1}admin/orders`,
  getWayBillData: `${API_COMMON_V1}warehouse/generate_package_slip/`,

  confirmOrder: `${API_COMMON_V1}admin/confirmOrderDispatch`,
  cofirmPackage: `${API_COMMON_V1}warehouse/pack/`,
  generateOrderInvoice: `${API_COMMON_V1}orders/invoice/`,

  // Customer list and details ,KYC API, wallet transactions
  getUsersList: `${API_COMMON_V1}admin/users`,
  getUserDetails: `${API_COMMON_V1}admin/users/`,
  getUserOrders: `${API_COMMON_V1}admin/users/`, //api/v1/admin/users/17/orders
  getUserTranactions: `${API_COMMON_V1}payouts/transactions`,
  getUserWalletDetails: `${API_COMMON_V1}payouts/account/`,

  getUserKyc: `${API_COMMON_V1}kyc/`,
  updateUserKycReason: `${API_COMMON_V1}kyc/reason/lock`,
  updateUserKyc: `${API_COMMON_V1}kyc/update`, //post request
  //post request
  getKycList: `${API_COMMON_V1}kyc/all`,

  getTree: `${API_COMMON_V1}refer/referrer/levels/`,

  // warehouse crud and stock update APIS in warehouses
  getAllWarehouses: `${API_COMMON_V1}warehouse/all`,
  getDistributorWarehouses: `${API_COMMON_V1}warehouse/access`,
  createWarehouse: `${API_COMMON_V1}warehouse/create`,

  //get stocks
  getWarehouseStock: `${API_COMMON_V1}warehouse/`,
  updateWarehouseStock: `${API_COMMON_V1}warehouse/update`,

  //get staff memebers
  getStaffMembers: `${API_COMMON_V1}admin/all`,
  createStaffMember: `${API_COMMON_V1}admin/create`,

  //Reports
  downloadTds: `${API_COMMON_V1}dashboard/TDS/excel`,
  downloadGst: `${API_COMMON_V1}dashboard/GST/excel`,
  downloadSales: `${API_COMMON_V1}dashboard/sales/excel`,
  //Payments
  paymentsPreview: `${API_COMMON_V1}payouts/process-payments-preview`,
  sendotp: `${API_COMMON_V1}admin/sendotp`,
  verifyOtpAndProcessPayments: `${API_COMMON_V1}payouts/process-payments`,

  historyicalTransactions: `${API_COMMON_V1}payouts/transactions`,

  variables: `${API_COMMON_V1}admin/variables`,
};

export const Token = localStorage.getItem("melmaa_token");
