export const DASHBOARD = "/";
export const PRODUCTS = "/products";
export const PRODUCTS_META = "/product-meta";
export const REVIEWS = "/reviews";

export const CATEGORIES = "/categories";
export const WAREHOUSE = "/warehouses";
export const WAREHOUSE_STOCK = "/warehouse-stock";

export const LOGIN = "/login";
export const ADMIN_LOGIN = "/admin-login";
export const SUPER_ADMIN_LOGIN = "/super-admin-login";
export const DISTRIBUTOR_LOGIN = "/seller-login";
export const KYC_ADMIN = "/kycadmin-login";
export const RECEPTIONIST = "/Receptionist-login";

export const LOGOUT = "/logout";

export const ORDERS = "/orders";
export const ORDER_DETAILS = "/order-details";
export const INVOICE_DOWNLOAD = "/invoice";
export const WAYBILL_DOWNLOAD = "/waybill";

export const CUSTOMERS = "/customers";
export const USERDETAIL = "/userdetail";
export const KYCLIST = "/kycs";
export const KYC_DETAIL = "/kyc-details";

export const SETTINGS = "/settings";
export const REPORTS = "/reports";

export const PAYMENTS = "/payments";

export const STAFF_MEMBERS = "/staff-members";
export const SITE_SETTINGS = "/site-settings";

export const CURRENCY = "₹";

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "ADMIN",
  DISTRIBUTOR: "DISTRIBUTOR",
  KYC_ADMIN: "KYC_ADMIN",
  RECEPTIONIST: "RECEPTIONIST",
};
