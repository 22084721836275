import { post, get } from "../config/api";
import { getHeaders } from "./utils";

export const Variables = {
  updateVariable: ({ id, key, value, stringify = true }) => {
    const headers = getHeaders();

    const preparedObject = {
      access: "USER",
      id: id,
      key: key,
      value: stringify ? JSON.stringify(value) : value,
    };

    if (headers) {
      return post("admin/variables/add", preparedObject, { headers });
    }
  },

  fetchVariables: () => {
    const headers = getHeaders();

    if (headers) {
      return get("admin/variables", { headers });
    }
    return get("admin/variables", { headers });
  },

  fetchCommonVariables: () => {
    const headers = getHeaders();

    if (headers) {
      return get("variables", { headers });
    }
    return get("variables", { headers });
  },

  addVariable: ({ key, value, stringify = true }) => {
    const headers = getHeaders();

    const preparedObject = {
      access: "USER",
      key: key,
      value: stringify ? JSON.stringify(value) : value,
    };

    if (headers) {
      return post("admin/variables/add", preparedObject, { headers });
    }
  },
};
