import React, { useContext, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import uuidv4 from "uuid/v4";

import { Button, Card, CardContent } from "@material-ui/core";

import SortableList, { convertToSortableProps } from "../SortableList";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputField from "../form-elements/InputField";
import EditorTextArea from "../form-elements/Editor";
import { BackdropContext } from "../../context/backdrop/backdrop.context";
import { Variables } from "../../services/variables";
import { SnackBarContext } from "../../context/snackbar/snackbar.context";
import { prepareSnackBarProperites } from "../snackbar";
import { Faq } from "../../services/faqs";

const FaqQuestion = ({
  onAddQuestion,
  onEditQuestion,
  open,
  handleClickOpen,
  handleClose,
  isEditMode,
  initialValues = {
    question: "",
    answer: "",
  },
}) => {
  const onSubmit = (values) => {
    if (isEditMode) {
      onEditQuestion(values);

      return;
    }

    onAddQuestion(values);
  };
  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleClickOpen}
      >
        Add Question
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            question: Yup.string().required("Question is required"),
            answer: Yup.string().required("Answer is required"),
          })}
          onSubmit={onSubmit}
        >
          <Form>
            <DialogTitle id="form-dialog-title">Add Question</DialogTitle>
            <DialogContent>
              <Field label="Question" component={InputField} name="question" />
              <br />
              <br />
              <Field
                label="Answer"
                config={{ height: "300px" }}
                component={EditorTextArea}
                name="answer"
              />
              <br />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="outlined" color="primary">
                {isEditMode ? "Update" : "Add"} Question
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      </Dialog>
    </div>
  );
};

const FaqTypes = {
  PRODUCT: "PRODUCT",
  CATEGORY: "CATEGORY",
  GENERIC: "GENERIC",
};

const FaqList = ({
  list,
  listKey,
  type,
  id = 0,
  onQuestionAdded = undefined,
  refresh = undefined,
  onDeleteFaq = undefined,
}) => {
  const [faqs, setFaqs] = useState([]);
  const [editQuestionProps, setEditQuestionProps] = useState(undefined);

  const { snackBarDispatch } = useContext(SnackBarContext);
  const { backdropDispatch } = useContext(BackdropContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditQuestionProps(undefined);
  };

  useEffect(() => {
    if (list) {
      const updatedList = convertToSortableProps(list, listKey);
      setFaqs(updatedList);
    }
  }, [list]);

  const onChangeOrder = ({ list: updatedList, removedIndex, addedIndex }) => {
    setFaqs(updatedList);
  };

  const onAddQuestion = async (values) => {
    backdropDispatch({ open: true, transitionDuration: 1000 });

    const tempList = [...faqs];
    const uuidId = uuidv4();
    tempList.push({ ...values, text: values[listKey], id: uuidId });
    const { question, answer } = values;
    switch (type) {
      case FaqTypes.GENERIC:
        const response = await Variables.updateVariable({
          id,
          key: "FAQS",
          value: tempList,
        });

        setFaqs(tempList);
        const snackbarproperties = prepareSnackBarProperites(
          "Faq Added",
          snackBarDispatch,
          "success",
          3000
        );
        handleClose();
        backdropDispatch({ open: false });
        snackBarDispatch(snackbarproperties);

        backdropDispatch({ open: false, transitionDuration: 1000 });
        break;

      case FaqTypes.PRODUCT:
        await Faq.addFaq({
          type: FaqTypes.PRODUCT,
          question,
          answer,
          entityId: id,
        })
          .then(() => {
            backdropDispatch({ open: false, transitionDuration: 1000 });
            if (onQuestionAdded) {
              onQuestionAdded();
            }

            const snackbarproperties = prepareSnackBarProperites(
              "Faq Added",
              snackBarDispatch,
              "success",
              3000
            );
            handleClose();
            backdropDispatch({ open: false });
            snackBarDispatch(snackbarproperties);
            handleClose();
          })
          .catch(() => {
            backdropDispatch({ open: false, transitionDuration: 1000 });
          });

        break;

      case FaqTypes.CATEGORY:
        await Faq.addFaq({
          type: FaqTypes.CATEGORY,
          question,
          answer,
          entityId: id,
        })
          .then(() => {
            backdropDispatch({ open: false, transitionDuration: 1000 });
            if (onQuestionAdded) {
              onQuestionAdded();
            }
            const snackbarproperties = prepareSnackBarProperites(
              "Faq Added",
              snackBarDispatch,
              "success",
              3000
            );
            handleClose();
            backdropDispatch({ open: false });
            snackBarDispatch(snackbarproperties);
            handleClose();
          })
          .catch(() => {
            backdropDispatch({ open: false, transitionDuration: 1000 });
          });

        break;
    }
  };

  const onEditQuestion = async (values) => {
    backdropDispatch({ open: true, transitionDuration: 1000 });


    switch (type) {
      case FaqTypes.GENERIC:
        const tempList = [...faqs];
        const index = tempList.findIndex((item) => item.id === editQuestionProps.id);
        tempList[index] = { ...values, text: values[listKey], id: editQuestionProps.id };

        const response = await Variables.updateVariable({
          id,
          key: "FAQS",
          value: tempList,
        });

        setFaqs(tempList);
        const snackbarproperties = prepareSnackBarProperites(
          "Faq Updated",
          snackBarDispatch,
          "success",
          3000
        );
        handleClose();
        backdropDispatch({ open: false });
        snackBarDispatch(snackbarproperties);
        break;
      default:
        await Faq.editFaq({
          ...values,
          id: editQuestionProps.id,
        })
          .then(() => {
            backdropDispatch({ open: false, transitionDuration: 1000 });
            setOpen(false);
            const snackbarproperties = prepareSnackBarProperites(
              "Question updated",
              snackBarDispatch,
              "success",
              3000
            );
            snackBarDispatch(snackbarproperties);
            handleClose();
          })
          .catch(() => {
            const snackbarproperties = prepareSnackBarProperites(
              "Not updated",
              snackBarDispatch,
              "error",
              3000
            );
            snackBarDispatch(snackbarproperties);
            backdropDispatch({ open: false, transitionDuration: 1000 });
          });
        break;
    }
  };

  const onDelete = async (questionId) => {
    backdropDispatch({ open: true, transitionDuration: 1000 });
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Are you sure you want to delete this question?")) {

      if (type === FaqTypes.GENERIC) {
        const updatedList = faqs.filter((item) => item.id !== questionId);
        await Variables.updateVariable({
          id,
          key: "FAQS",
          value: updatedList,
        });
        setFaqs(updatedList);
        const snackbarproperties = prepareSnackBarProperites(
          "Faq Deleted",
          snackBarDispatch,
          "success",
          3000
        );
        snackBarDispatch(snackbarproperties);
        backdropDispatch({ open: false, transitionDuration: 1000 });
      } else {

        await Faq.deleteFaq(questionId)
          .then(() => {
            backdropDispatch({ open: false, transitionDuration: 1000 });
            onDeleteFaq && onDeleteFaq(questionId);
            const snackbarproperties = prepareSnackBarProperites(
              "Question Deleted",
              snackBarDispatch,
              "success",
              3000
            );
            refresh && refresh();
            snackBarDispatch(snackbarproperties);
          })
          .catch(() => {
            const snackbarproperties = prepareSnackBarProperites(
              "Erorr deleting question",
              snackBarDispatch,
              "error",
              3000
            );
            snackBarDispatch(snackbarproperties);
            backdropDispatch({ open: false, transitionDuration: 1000 });
          });
      }
    }
  };

  // const [isEditMode, setEditMode] = useState(false);

  const onEdit = (questionId) => {
    setOpen(true);
    const question = faqs.find((faq) => faq.id === questionId);

    setEditQuestionProps({
      editMode: true,
      question: question,
      id: questionId,
    });
  };

  const getInitialValues = () => {
    if (!editQuestionProps)
      return {
        question: "",
        answer: "",
      };

    const { question, answer } = editQuestionProps.question;
    return {
      question,
      answer,
    };
  };

  const getMode = () => {
    if (!editQuestionProps) return false;
    return editQuestionProps.editMode;
  };

  useEffect(() => {
    if (editQuestionProps) {
      setOpen(true);
    }
  }, [editQuestionProps]);

  return (
    <Card>
      <CardContent>
        <FaqQuestion
          open={open}
          initialValues={getInitialValues()}
          isEditMode={getMode()}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          onAddQuestion={onAddQuestion}
          onEditQuestion={onEditQuestion}
        />
        <br />
        <br />
        <SortableList
          onDelete={onDelete}
          onEdit={onEdit}
          deletable
          onChangeOrder={onChangeOrder}
          editable
          list={faqs}
        />
      </CardContent>
    </Card>
  );
};

export default FaqList;

export { FaqTypes };
