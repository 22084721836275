import axios from "axios";
import { API_COMMON_V1 } from "../settings/api-endpoints";

export const API_URL = API_COMMON_V1;

const apiClient = axios.create({
  baseURL: API_URL, // <- ENV variable
});
// apiClient.interceptors.request.use((config) => {
//   return ({
//     ...config,
//     headers: {
//     },
//   })
// },
//   error => Promise.reject(error),
// );

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
      return;
    }
    return Promise.reject(error.response.data);
  }
);

const { get, post, put, delete: destroy } = apiClient;
export { get, post, put, destroy };
