import React, { useEffect } from 'react';
import { Variables } from '../services/variables';
import { API_V1 } from '../settings/api-endpoints';

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signout: Function;
  variables: any;
  logo: string;
  fetchVariables: Function;
};

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem('melmaa_token');
  // JWT decode & check token validity & expiration.
  if (token) return true;
  return false;
};




const AuthProvider = (props: any) => {

  const [isAuthenticated, makeAuthenticated] = React.useState(isValidToken());
  const [variables, setVariables] = React.useState({});
  const [logo, setLogo] = React.useState('');

  const fetchVariables = React.useCallback(() => {
    Variables.fetchCommonVariables().then((res) => {
      const { data } = res?.data;
      const logoObject = data.find((item: any) => item.key === 'LOGO');
      if (logoObject) setLogo(logoObject.value);
      setVariables(data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  useEffect(() => {
    fetchVariables();
  }, []);

  function authenticate({ username, password, role }, cb) {
    const preparedRequest: RequestInit = {
      headers: new Headers({ 'content-type': 'application/json' }),
      method: 'POST',
      body: JSON.stringify({ username, password, role })
    };
    try {
      fetch(API_V1.login, preparedRequest).then((response: Response) => {
        if (response.ok) {
          response.json().then((data) => {
            makeAuthenticated(true);
            console.log(" login success");
            localStorage.setItem('melmaa_token', `${data.data.token}`);
            cb();

          })
        }
        else {
          console.log("statu code and readson", response.status);
          cb();
        }
      }).catch((reason: any) => {
        console.log("statu code and readson", reason);
        makeAuthenticated(false);
      })
    }
    catch (e) {
      console.log("e==>", e);
    }

  }

  function signout(cb) {
    makeAuthenticated(false);
    localStorage.removeItem('melmaa_token');
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signout,
        logo,
        fetchVariables,
        variables,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
