import React, { useState } from "react"; 

import { SnackBarContext } from "./snackbar.context"; 
import { SnackbarProps } from "@material-ui/core"; 
import { AlertProps } from "@material-ui/lab/Alert/Alert";
import { CustomSnackbar } from "../../components/snackbar";


export interface SnackBarProps { 
  snackbarProps: SnackbarProps; 
  alertProps: AlertProps;
}
 

const INITIAL_STATE: SnackBarProps = {
    snackbarProps: {},
    alertProps: {} 
};

export const SnackBarProvider: React.FunctionComponent = ({ children }) => {
  const [snackBarState, snackBarDispatch] = useState(INITIAL_STATE);
  return (
    <SnackBarContext.Provider value={{ snackBarState, snackBarDispatch }}>
      <CustomSnackbar {...snackBarState} />
      {children} 
    </SnackBarContext.Provider>
  );
};
