import { get, post, destroy } from "../config/api";
import { getHeaders } from "./utils";

export const Product = {
  addProductImage: ({ productId, productImageUrls }) => {
    const token = localStorage.getItem("melmaa_token");
    if (token) {
      return post(
        "product/add-productImages",
        { productId, productImageUrls },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  },

  deleteImage: ({ productId, imageId }) => {
    const headers = getHeaders();
    if (headers) {
      return destroy(
        `product/remove-product-image?productImageId=${imageId}&productId=${productId}`,
        {
          headers,
        }
      );
    }
  },

  getProduct: (id) => {
    const headers = getHeaders();
    if (headers) {
      return get(`product/${id}`, {
        headers,
      });
    }
  },

  getComboProducts: (id) => {
    const headers = getHeaders();
    if (headers) {
      return get(`product/${id}/combolist`, {
        headers,
      });
    }
  },

  getAllProducts: () => {
    const headers = getHeaders();
    if (headers) {
      return get("product/all?pageSize=10000", {
        headers,
      });
    }
  },

  addCombo: ({ productId, comboId }) => {
    const headers = getHeaders();
    if (headers) {
      return post(
        `product/combo`,
        { productA: productId, productB: comboId },
        { headers }
      );
    }
  },

  deleteCombo: (comboId) => {
    const headers = getHeaders();
    if (headers) {
      return destroy(`product/combo/${comboId}`, { headers });
    }
  },

  getCategories: (productId) => {
    const headers = getHeaders();
    if (headers) {
      return get(`category/${productId}/categories`, { headers });
    }
  },
};
