import React, { useEffect, useState, useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { styled } from "baseui";
import { UploadIcon } from "../../assets/icons/UploadIcon";
import Delimg from "../../assets/image/del.png";
import { API_V1, Token } from "../../settings/api-endpoints";
import { Button } from "@material-ui/core";
import { BackdropContext } from "../../context/backdrop/backdrop.context";
import { Upload } from "../../services/upload";
const Text = styled("span", ({ $theme }) => ({
  ...$theme.typography.font14,
  fontFamily: $theme.typography.primaryFontFamily,
  color: $theme.colors.textDark,
  marginTop: "15px",
  textAlign: "center",
}));

const TextHighlighted = styled("span", ({ $theme }) => ({
  color: $theme.colors.primary,
  fontWeight: "bold",
}));

const Container = styled("div", ({ props }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "#E6E6E6",
  borderStyle: "dashed",
  backgroundColor: "#ffffff",
  color: "#bdbdbd",
  outline: "none",
  transition: "border 0.24s ease-in-out",
  cursor: "pointer",
}));

const ThumbsContainer = styled("aside", () => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: "16px",
}));

const Thumb = styled("div", ({ $theme }) => ({
  ...$theme.borders.borderEA,
  display: "inline-flex",
  borderRadius: "2px",
  marginBottom: "8px",
  marginRight: "8px",
  height: "100px",
  padding: "4px",
  boxSizing: "border-box",
}));

const thumbInner = {
  display: "flex",
  minWidth: 0,
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

function Uploader({
  onChange,
  imagesList,
  deleteImage,
  files: selectedFilesList,
  onUploadImages,
  multlple = false,

  id = 0,
  type = "GENERIC",
}: any) {
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const { backdropDispatch } = useContext(BackdropContext);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: multlple,
    onDrop: useCallback(
      (acceptedFiles) => {
        setSelectedFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
        onChange && onChange(acceptedFiles);
      },
      [onChange]
    ),
  });

  const addImages = async () => {
    backdropDispatch({ open: true, transitionDuration: 1000 });
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      await Upload.uploadFiles({ file, type, id: id })
        // eslint-disable-next-line no-loop-func
        .then((response) => {
          const { data } = response;
          const { data: uploadedImageUrl } = data;
          backdropDispatch({ open: false, transitionDuration: 1000 });
          onUploadImages(uploadedImageUrl);
        })
        .catch(() => {
          backdropDispatch({ open: false, transitionDuration: 1000 });
          return;
        });
    }

    setSelectedFiles([]);
  };

  const selectedThumbs = (
    <>
      {selectedFiles?.map((file) => (
        <>
          <Thumb key={file.name}>
            <div style={thumbInner}>
              <span
                style={{
                  position: "relative",
                  top: "0px",
                  right: "0px",
                  zIndex: 11,
                  cursor: "pointer",
                }}
                onClick={() => deleteImage(file.id)}
              >
                <img height={20} src={Delimg} alt="delete" />
              </span>
              <img
                src={file.preview ? file.preview : file.productImageUrl}
                style={img}
                alt={file.name}
              />
            </div>
          </Thumb>
        </>
      ))}
      <br />
      {selectedFiles && selectedFiles.length !== 0 && (
        <Button onClick={addImages} variant="outlined" color="primary">
          Upload
        </Button>
      )}
    </>
  );

  const thumbs = files?.map((file) => (
    <Thumb key={file.name}>
      <div style={thumbInner}>
        <span
          style={{
            position: "relative",
            top: "0px",
            right: "0px",
            zIndex: 11,
            cursor: "pointer",
          }}
          onClick={() => deleteImage(file.id)}
        >
          <img height={20} src={Delimg} alt="delete" />
        </span>
        <img
          src={file.preview ? file.preview : file.productImageUrl}
          style={img}
          alt={file.name}
        />
      </div>
    </Thumb>
  ));

  useEffect(() => {
    setFiles(imagesList);
  }, [imagesList]);

  useEffect(() => {
    setSelectedFiles(selectedFilesList);
  }, [selectedFilesList]);

  return (
    <section className="container uploader">
      <Container {...getRootProps()}>
        <input {...getInputProps()} />
        <UploadIcon />
        <Text>
          <TextHighlighted>Drag/Upload</TextHighlighted> your image here.
        </Text>
      </Container>
      {selectedThumbs && <ThumbsContainer>{selectedThumbs}</ThumbsContainer>}
      <br />
      {thumbs && <ThumbsContainer>{thumbs}</ThumbsContainer>}
    </section>
  );
}

export default Uploader;
